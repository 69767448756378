import React, { Suspense } from "react";
import { Link } from "react-router-dom";
import { useImage } from "react-image";
import { useSelector } from "react-redux";
import moment from "moment";
import Button from "../ui/Button";

export default function BlogCard({ blog }) {
  const settingsData = useSelector((state) => state.settings);
  const { theme } = settingsData;

  const blogPreviewState = useSelector((state) => state.blogPreview);
  const { preview } = blogPreviewState;

  const BlogImage = React.memo(({ imgSrc, alt, className }) => {
    const { src } = useImage({
      srcList: imgSrc,
    });

    return (
      <img
        className={className}
        style={{ height: preview === "card" && "100%" }}
        src={src}
        alt={alt}
      />
    );
  });

  return (
    <>
      <div
        className={`card product-card card-shadow border-1 rounded-0 p-4 w-100 border-transition-hover-${theme}-500`}
        key={blog._id}
      >
        <div
          style={{ height: preview === "grid" ? "350px" : "100%" }}
          className="d-flex flex-column justify-content-between gap-2"
        >
          <p className="text-gray">
            {moment(blog?.createdAt).format("DD.MM.YYYY")}
          </p>
          <div
            style={{ height: preview === "card" && "100%" }}
            className="shopBack h-50"
          >
            <Suspense fallback={<span>Ucitavanje...</span>}>
              <BlogImage
                className={`h-75 ${preview === "card" ? "d-block m-auto" : ""}`}
                imgSrc={blog.image.src}
                alt={blog.title}
              />
            </Suspense>
          </div>

          <div className="d-flex justify-content-center">
            <Link
              className={`d-block ${preview === "card" ? "w-50" : "w-100"}`}
              to={`/client-blogs/${blog._id}`}
            >
              <Button className="w-100" variant="outline">
                Saznaj vise
              </Button>
            </Link>
          </div>

          <div className={`pt-3 ${preview === "card" ? "d-flex px-1" : ""}`}>
            <h3 className={`text-${theme}-500 d-block text-truncate`}>
              {blog.title}
            </h3>
          </div>
        </div>
      </div>
    </>
  );
}
