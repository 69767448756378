import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import ImageGallery from "react-image-gallery";
import styled from "styled-components";
import "react-image-gallery/styles/css/image-gallery.css";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";

const Target = styled.img.attrs(({ offset, src, opacity }) => ({
  style: {
    left: `${offset.left}px`,
    top: `${offset.top}px`,
    opacity: opacity,
  },
  src: src,
}))`
  position: absolute;
  width: auto !important;
`;

const SlideshowContainer = styled.div`
  position: relative;
  overflow: hidden;
  img {
    display: block;
    width: 100%;
    pointer-events: none;
  }
  .image-gallery-slide-wrapper {
    overflow: hidden;
    border: 1px solid #ccc;
  }
  .image-gallery-slides,
  .image-gallery-slide {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .image-gallery-content {
    width: 100%;
  }
  .image-gallery-slide img {
    height: 400px;
    margin-top: 12px;
  }
  .zoom-target-elem {
    position: absolute;
    width: auto !important;
    pointer-events: none;
  }
`;

const Slideshow = ({ sliderImages }) => {
  const { theme } = useSelector((state) => state.settings);

  const targetRef = useRef(null);
  const mountedRef = useRef(true);

  const [image, setImage] = useState(null);
  const [container, setContainer] = useState(null);
  const [opacity, setOpacity] = useState(0);
  const [offset, setOffset] = useState({});
  const [targetWrapper, setTargetWrapper] = useState(null);

  const handleMouseEnter = useCallback((e) => {
    if (
      document
        .querySelector(".image-gallery-content")
        .classList.contains("fullscreen")
    )
      return;

    if (!e.target.classList.contains("image-gallery-slide")) return;

    setOpacity(1);
  }, []);
  const handleMouseLeave = useCallback(() => setOpacity(0), []);

  const handleMouseMove = useCallback(
    (e) => {
      e.preventDefault();

      if (
        document
          .querySelector(".image-gallery-content")
          .classList.contains("fullscreen")
      )
        return;

      if (!e.target.classList.contains("image-gallery-slide")) return;

      const isTouchEvent = e.type === "touchmove";
      const clientX = isTouchEvent ? e.touches[0].clientX : e.pageX;
      const clientY = isTouchEvent ? e.touches[0].clientY : e.pageY;

      if (!opacity) setOpacity(1);

      if (!image || !container) return;

      const targetRect = targetRef.current?.getBoundingClientRect();
      const sourceRect = image?.getBoundingClientRect();
      const containerRect = container?.getBoundingClientRect();

      if (!targetRect || !sourceRect) return;

      const xRatio =
        (targetRect.width - containerRect.width) / sourceRect.width;
      const yRatio =
        (targetRect.height - containerRect.height) / sourceRect.height;

      const left = Math.max(
        Math.min(clientX - sourceRect.left, sourceRect.width),
        0
      );
      const top = Math.max(
        Math.min(clientY - sourceRect.top, sourceRect.height),
        0
      );

      setOffset({
        left: left * -xRatio,
        top: top * -yRatio,
      });

      return;
    },
    [image, container, opacity]
  );

  const onImageClick = useCallback(() => {
    if (
      document
        .querySelector(".image-gallery-content")
        .classList.contains("fullscreen")
    )
      return;

    document.querySelector(".image-gallery-fullscreen-button").click();
  }, []);

  useEffect(() => {
    if (isEmpty(sliderImages)) return;

    const img = document.querySelector(".image-gallery-center img");
    setImage(img);

    const container = document.querySelector(".image-gallery-slide-wrapper");
    setContainer(container);

    return () => {
      mountedRef.current = false;
    };
  }, [sliderImages]);

  useEffect(() => {
    if (container) {
      // Desktop Events
      container.addEventListener("mouseenter", handleMouseEnter);
      container.addEventListener("mouseleave", handleMouseLeave);
      container.addEventListener("mousemove", handleMouseMove);

      // Mobile Events
      container.addEventListener("touchstart", handleMouseEnter);
      container.addEventListener("touchend", handleMouseLeave);
      container.addEventListener("touchmove", handleMouseMove);

      return () => {
        // Remove Desktop Events
        container.removeEventListener("mouseenter", handleMouseEnter);
        container.removeEventListener("mouseleave", handleMouseLeave);
        container.removeEventListener("mousemove", handleMouseMove);

        // Remove Mobile Events
        container.removeEventListener("touchstart", handleMouseEnter);
        container.removeEventListener("touchend", handleMouseLeave);
        container.removeEventListener("touchmove", handleMouseMove);
      };
    }
  }, [container, handleMouseEnter, handleMouseLeave, handleMouseMove]);

  useEffect(() => {
    if (isEmpty(sliderImages)) return;

    const images = document.querySelectorAll(".image-gallery-slide");
    images.forEach((img) => img.addEventListener("click", onImageClick));

    return () => {
      images.forEach((img) => img.removeEventListener("click", onImageClick));
    };
  }, [sliderImages, onImageClick]);

  useEffect(() => {
    const handleResize = () => {
      if (!mountedRef.current) return;

      const img = document.querySelector(".image-gallery-center img");
      if (img) {
        setImage(img);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const slideWrapper = document.querySelector(".image-gallery-slide-wrapper");
    if (slideWrapper) {
      setTargetWrapper(slideWrapper);
    }
  }, [image, offset]);

  return (
    <SlideshowContainer theme={theme}>
      {!isEmpty(sliderImages) && (
        <ImageGallery
          items={sliderImages}
          onScreenChange={() => setOpacity(0)}
          disableSwipe
          onSlide={() => {
            const img = document.querySelector(".image-gallery-center img");
            if (img) {
              setImage(img);
            }
          }}
        />
      )}
      {targetWrapper &&
        ReactDOM.createPortal(
          <Target
            className="zoom-target-elem"
            ref={targetRef}
            alt="target"
            opacity={opacity}
            offset={offset}
            src={image?.src}
          />,
          targetWrapper
        )}
    </SlideshowContainer>
  );
};

export default React.memo(Slideshow, (prevProps, nextProps) => {
  return prevProps.sliderImages === nextProps.sliderImages;
});
