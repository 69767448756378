import React from "react";
import { Fade } from "react-slideshow-image";
import { MovingComponent } from "react-moving-text";
import "react-slideshow-image/dist/styles.css";
import { useSelector } from "react-redux";
import parse from "html-react-parser";
import { useNavigate } from "react-router-dom"; // Replaced useHistory with useNavigate
import Button from "../ui/Button"

const MainSlideshow = () => {
  const settingsData = useSelector((state) => state.settings);
  const { main_slideshow, theme, loading: settingsLoading } = settingsData;

  const productList = useSelector((state) => state.clientProductListDiscount);
  const { loading } = productList;

  const brandsData = useSelector((state) => state.brandList);
  const { loading: brandsLoading } = brandsData;

  const categoriesList = useSelector((state) => state.categoriesList);
  const { loading: catLoading, categories } = categoriesList;

  const navigate = useNavigate();

  const onSubmit = (e) => {
    e.preventDefault();
    if (categories && categories[0]) {
      navigate(`/shop/${categories[0].path}`);
    }
  };

  return (
    <div className="slide-container position-relative site-bg">
      {!loading && !brandsLoading && !catLoading && !settingsLoading && (
        <Fade pauseOnHover={false} arrows={false}>
          {main_slideshow?.images.map((image, i) => (
            <div className="each-fade" key={image._id}>
              <img src={image.src} alt={i} />
            </div>
          ))}
        </Fade>
      )}

      <div
        style={{ zIndex: 1 }}
        className="slide_action position-absolute top-0 left-0 w-100 h-100 d-flex justify-content-center items-center"
      >
        <div className="container d-flex justify-content-start justify-content-lg-start justify-content-md-start justify-content-center">
          <div className="slide_action_half">
            {!loading && !brandsLoading && !catLoading && !settingsLoading && (
              <MovingComponent
                type="fadeInFromRight"
                duration="1200ms"
                delay="0s"
                direction="normal"
                timing="ease"
                iteration="1"
                fillMode="none"
              >
                {parse(main_slideshow?.title || "")}

                <form className="form-section" onSubmit={onSubmit}>
                  <Button
                    className="mt-4 px-4 py-3 text-uppercase"
                    name="subscribe"
                    type="submit"
                  >
                    Idi na proizvode
                  </Button>
                </form>
              </MovingComponent>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(MainSlideshow);
