import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listHomeProductAll } from "../../Redux/client/Actions/ProductActions";
import Header from "../../components/client/Header";
import MainSlideshow from "../../components/client/home-components/MainSlideshow";
import ProductCarousel from "../../components/client/common/ProductCarousel";
import ContactInfo from "../../components/client/common/ContactInfo";
import CalltoActionSection from "../../components/client/home-components/CalltoActionSection";
import Footer from "../../components/client/Footer";
import Loading from "../../components/client/loading-error/Loading";
import GridCards from "../../components/client/common/GridCards";
import BrandCarousel from "../../components/client/common/BrandCarousel";
import ErrorBoundary from "../../utils/ErrorBoundary";
import Jumbotron from "../../components/client/home-components/Jumbotron";
import { Helmet } from "react-helmet";

const HomeScreen = () => {
  const dispatch = useDispatch();

  const productList = useSelector((state) => state.clientProductListDiscount);
  const { loading, products } = productList;

  const brandsData = useSelector((state) => state.brandList);
  const { loading: brandsLoading } = brandsData;

  const categoriesList = useSelector((state) => state.categoriesList);
  const { loading: catLoading } = categoriesList;

  const settingsData = useSelector((state) => state.settings);
  const { homeBg } = settingsData;

  useEffect(() => {
    dispatch(listHomeProductAll());
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <title>Pocetna stranica</title>
        <meta name="description" content="This is a description of my page" />
        <meta name="keywords" content="react, meta tags, seo" />
        <meta name="author" content="Your Name" />
        <meta property="og:title" content="My Page Title" />
        <meta
          property="og:description"
          content="This is a description of my page"
        />
        <meta property="og:image" content="https://example.com/image.jpg" />
        <meta property="og:url" content="https://example.com/my-page" />
        <meta name="twitter:title" content="My Page Title" />
        <meta
          name="twitter:description"
          content="This is a description of my page"
        />
        <meta name="twitter:image" content="https://example.com/image.jpg" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Header />
      <div
        style={{ backgroundImage: `url(${homeBg ? homeBg[0]?.src : ""})` }}
        className="home_wrapper position-relative"
      >
        <ErrorBoundary>
          <MainSlideshow />
        </ErrorBoundary>
        <Jumbotron />
        <GridCards />
        <ProductCarousel products={products} title="IZDVOJENO IZ PONUDE" />
        <CalltoActionSection />
        <BrandCarousel />
        <ContactInfo />
        <Footer />
        {(loading || brandsLoading || catLoading) && <Loading />}
      </div>
    </>
  );
};

export default HomeScreen;
