import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";
import parse from "html-react-parser";

export default function DesktopMenu({ isHeaderSticky }) {
  const [slug, setSlug] = useState("");

  const fade = useRef();
  const menuTrigger = useRef();

  const categoriesList = useSelector((state) => state.clientCategoriesList);
  const { categories } = categoriesList;

  const settingsData = useSelector((state) => state.settings);
  const { theme, contactPage } = settingsData;

  const history = useNavigate();

  const fadeIn = () => {
    const leftPosition = menuTrigger.current.getBoundingClientRect().left;
    let timeout;
    clearTimeout(timeout);
    fade.current.classList.add("dropdown-menu-show");
    fade.current.style.left = `-${leftPosition}px`;
    timeout = setTimeout(() => {
      fade.current.classList.add("dropdown-menu-visible");
    }, 100);
  };

  const fadeOut = () => {
    let timeout;
    clearTimeout(timeout);
    fade.current.classList.remove("dropdown-menu-show");
    timeout = setTimeout(() => {
      fade.current.classList.remove("dropdown-menu-visible");
    }, 100);
  };

  const recursiveMenuItems = (items, id) => {
    return (
      <ul
        className={`list-group list-group-flush p-0 m-0 border-${theme}-200`}
        key={id}
      >
        {items.map((item) => {
          return (
            <li
              className={`list-group-item list-group-item-action pl-1 d-flex gap-1 user-select-none site-bg`}
              key={item._id}
            >
              <img
                src={item.images[0]?.src}
                alt="Item image"
                width={20}
                height={20}
              />
              <a
                href={`/shop/${item.path}`}
                className="d-flex justify-content-between w-100 h-100"
              >
                <span>
                  {item.title}
                  {item.children.length > 0 && (
                    <i className="fas fa-caret-right p-0 m-0"></i>
                  )}
                </span>
              </a>
              <span
                className={`bg-${theme}-500 bottom-line-category ${
                  item.path === slug ? `w-100 left-0` : ""
                }`}
              ></span>

              {item.children.length > 0 && recursiveMenuItems(item.children)}
            </li>
          );
        })}
      </ul>
    );
  };

  const menuGenerator = (items) => {
    const groupData = (data) => {
      const result = [];
      let i = 0;

      while (i < data.length) {
        if ((result.length + 1) % 2 !== 0) {
          // If it's an odd group
          result.push(data.slice(i, i + 5));
          i += 5;
        } else {
          // If it's an even group
          result.push(data.slice(i, i + 6));
          i += 6;
        }
      }

      return result;
    };

    const groupedData = groupData(items);

    return groupedData.map((items, i) => {
      return (
        <div
          key={`menu-${i}`}
          className="desktop-menu-holder w-100 d-flex flex-wrap justify-content-center my-4"
        >
          {items.map((item) => (
            <div
              style={{ width: "16.6666666%" }}
              className="mb-3 mb-lg-0 top-category"
              key={item._id}
            >
              <a
                href={`/shop/${item.path}`}
                className={`user-select-none d-flex gap-1 align-items-center flex-column text-uppercase font-weight-bold w-100 px-1 mb-2 py-2 list-group-item-action text-${theme}-600`}
              >
                <div className="d-flex justify-content-center">
                  <img
                    src={item.images[0]?.src}
                    alt="Item image"
                    height={70}
                  />
                </div>
                <div className="d-flex flex-grow-1 text-center justify-content-end align-items-center gap-2">
                  {item.title}
                  {item.children.length > 0 && (
                    <i className="fas fa-caret-right pb-1 m-0"></i>
                  )}
                </div>

                <span
                  className={`bg-${theme}-500 bottom-line-category ${
                    item.path === slug ? `w-100 left-0` : ""
                  }`}
                ></span>
              </a>
              {item.children.length > 0 &&
                recursiveMenuItems(item.children, item._id)}
            </div>
          ))}
        </div>
      );
    });
  };

  useEffect(() => {
    if (history?.location?.pathname) {
      const pathname = history.location.pathname.split("/");
      setSlug(pathname[pathname.length - 1]);
    }
  }, [history]);

  return (
    <nav className="navbar desktop-menu row navbar-expand-lg navbar-light py-0">
      <div className="container-fluid px-0 mx-0">
        <button
          className="navbar-toggler px-0 d-sm-none"
          type="button"
          data-mdb-toggle="collapse"
          data-mdb-target="#navbarExampleOnHover"
          aria-controls="navbarExampleOnHover"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i className="fas fa-bars"></i>
        </button>

        {/* Collapsible wrapper */}
        <div className="collapse navbar-collapse" id="navbarExampleOnHover">
          {/* Left links */}
          <ul className="navbar-nav flex-wrap me-auto ps-lg-0">
            {/* Navbar dropdown */}
            <li
              ref={menuTrigger}
              onMouseOver={fadeIn}
              onMouseLeave={fadeOut}
              className={`nav-item dropdown desktop-menu-trigger position-relative text-gray ${
                !isHeaderSticky
                  ? `text-transition-hover-${theme}-500`
                  : "text-white"
              }`}
            >
              <a
                className={`nav-link dropdown-toggle ${
                  !isHeaderSticky
                    ? `text-transition-hover-${theme}-500`
                    : "text-white"
                }`}
                href="#"
                id="navbarDropdown"
                role="button"
                data-mdb-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="fal fa-bars"></i>
                <span className="px-1">MENI</span>
              </a>
              <span
                className={`${
                  !isHeaderSticky ? `bg-${theme}-500` : "bg-white"
                } bottom-line-active`}
              ></span>
              {/* Dropdown menu */}
              <div
                ref={fade}
                className={`dropdown-menu desktop-menu overflow-visible py-3 shadow mt-0 w-100 border-bottom-4-${theme}-500 bg-${theme}-50`}
                aria-labelledby="navbarDropdown"
                style={{
                  borderTopLeftRadius: 0,
                  borderTopRightRadius: 0,
                }}
              >
                <div
                  style={{ zIndex: 1 }}
                  className="container gap-1 d-flex flex-column"
                >
                  {!isEmpty(categories) && menuGenerator(categories)}
                </div>
              </div>
            </li>

            <li
              className={`nav-item dropdown desktop-menu-trigger position-relative ${
                history?.location?.pathname === "/" && !isHeaderSticky
                  ? `text-${theme}-500 text-transition-hover-${theme}-500`
                  : "text-white"
              }`}
            >
              <a
                className={`nav-link ${
                  !isHeaderSticky
                    ? `text-transition-hover-${theme}-500`
                    : "text-white"
                }`}
                href="/"
                id="navbarDropdown"
                role="button"
              >
                <i className="fal fa-home"></i>
                <span className="px-1">Pocetna</span>
              </a>
              <span
                className={`${
                  !isHeaderSticky ? `bg-${theme}-500` : "bg-white"
                } ${
                  history?.location?.pathname === "/" ? `w-100 left-0` : ""
                } bottom-line-active`}
              ></span>
            </li>
            <li
              className={`nav-item dropdown desktop-menu-trigger position-relative ${
                history?.location?.pathname === "/contact" && !isHeaderSticky
                  ? `text-${theme}-500 text-transition-hover-${theme}-500`
                  : "text-white"
              }`}
            >
              <a
                className={`nav-link ${
                  !isHeaderSticky
                    ? `text-transition-hover-${theme}-500`
                    : "text-white"
                }`}
                href="/contact"
                id="navbarDropdown"
                role="button"
              >
                <i className="fal fa-map-marker-alt"></i>
                <span className="px-1">Kontakt</span>
              </a>
              <span
                className={`${
                  !isHeaderSticky ? `bg-${theme}-500` : "bg-white"
                } ${
                  history?.location?.pathname === "/contact"
                    ? `w-100 left-0`
                    : ""
                } bottom-line-active`}
              ></span>
            </li>
            <li
              className={`nav-item dropdown desktop-menu-trigger position-relative ${
                history?.location?.pathname === "/client-blogs" &&
                !isHeaderSticky
                  ? `text-${theme}-500 text-transition-hover-${theme}-500`
                  : "text-white"
              }`}
            >
              <a
                className={`nav-link ${
                  !isHeaderSticky
                    ? `text-transition-hover-${theme}-500`
                    : "text-white"
                }`}
                href="/client-blogs"
                id="navbarDropdown"
                role="button"
              >
                <i className="fal fa-newspaper"></i>
                <span className="px-1">Novosti</span>
              </a>
              <span
                className={`${
                  !isHeaderSticky ? `bg-${theme}-500` : "bg-white"
                } ${
                  history?.location?.pathname === "/client-blogs"
                    ? `w-100 left-0`
                    : ""
                } bottom-line-active`}
              ></span>
            </li>
          </ul>
          {/* Left links */}
        </div>
        {/* Collapsible wrapper */}
        <div className="col-md-5 d-flex justify-content-end gap-5 display-none">
          <a
            href={`tel:${contactPage?.contactPhone || ""}`}
            className={`d-flex ${
              isHeaderSticky ? "text-white" : "text-gray"
            } gap-1 align-items-center`}
          >
            <i className="fal fa-phone px-1"></i>
            {parse(contactPage?.contactPhone || "")}
          </a>
          <a
            href={`mailto:${contactPage?.email || ""}`}
            className={`d-flex ${
              isHeaderSticky ? "text-white" : "text-gray"
            } gap-1 align-items-center`}
          >
            <i className="fal fa-envelope px-1"></i>
            {parse(contactPage?.email || "")}
          </a>
        </div>
      </div>
    </nav>
  );
}
