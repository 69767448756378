import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  singleUser,
  blockUser,
  removeUser,
} from "../../../Redux/dashboard/Actions/UserActions";
import Loading from "../loading-error/Loading";
import Message from "../loading-error/Error";
import TopTotal from "../common/TopTotal";
import MainProducts from "../common/MainProducts";
import { Link, useNavigate } from "react-router-dom"; // Use the correct import
import CenteredModal from "../common/Modal";
import { Section, Card, CardHeader, CardBody } from "../common";
import { REMOVE_USER_SUCCESS } from "../../../Redux/dashboard/Constants/UserContants";

export default function UserMain({ userId }) {
  const [modalShow, setModalShow] = useState(false);
  const [submithandler, setSubmithandler] = useState(false);
  const [reqid, setReqid] = useState(false);
  const [modalTitle, setModaltitle] = useState("");
  const [showDetails, setShowDetails] = useState(false);

  const userData = useSelector((state) => state.singleUser);
  const { loading, error, user } = userData;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const productsData = useSelector((state) => state.productList);
  const { products } = productsData;

  const ordersData = useSelector((state) => state.orderList);
  const { orders } = ordersData;

  const themeData = useSelector((state) => state.theme);
  const { theme } = themeData;

  const removeUserData = useSelector((state) => state.removeUser);
  const {
    loading: removeLoading,
    error: removeError,
    removedUser,
  } = removeUserData;

  const navigate = useNavigate(); // Use useNavigate hook

  const dispatch = useDispatch();

  const blockUserHandler = (id) => {
    dispatch(blockUser(id));
  };

  const removeUserHandler = (id) => {
    dispatch(removeUser(id));
  };

  useEffect(() => {
    if (dispatch && userId) {
      dispatch(singleUser(userId));
    }
  }, [dispatch, userId]);

  useEffect(() => {
    if (removedUser?.removed) {
      navigate("/users");
    }
  }, [removedUser, navigate]);

  useEffect(() => {
    return () =>
      dispatch({ type: REMOVE_USER_SUCCESS, payload: { removedUser: false } });
  }, []);

  return (
    <>
      <Section>
        <Card section className="mb-4 shadow-sm">
          <Card section className="card-user shadow-sm">
            <CardHeader section>
              <div className="position-absolute left-0 top-0 p-2">
                <Link
                  to="/users"
                  className={`btn ${
                    theme === "dark" ? "btn-dark" : "btn-light"
                  }`}
                >
                  Nazad na korisnike
                </Link>
              </div>
              {userInfo?.isSuperAdmin && (
                <div className="position-absolute p-2 d-flex top-0 right-0">
                  <div className="dropdown">
                    <Link
                      to="#"
                      data-bs-toggle="dropdown"
                      className={`btn ${
                        theme === "dark" ? "btn-dark" : "btn-light"
                      }`}
                    >
                      <i className="fas fa-ellipsis-h"></i>
                    </Link>
                    <div
                      className={`dropdown-menu ${
                        theme === "dark" ? "bg-dark" : ""
                      }`}
                    >
                      <Link
                        className={`dropdown-item ${
                          theme === "dark" ? "text-white dark-theme-hover" : ""
                        }`}
                        to="#"
                        onClick={(e) => {
                          e.preventDefault();
                          setReqid(user._id);
                          setSubmithandler({ call: blockUserHandler });
                          setModaltitle("Blokiraj korisnika");
                          setShowDetails(true);
                          setModalShow(true);
                        }}
                      >
                        <i className="fas fa-solid fa-ban"></i> Blokiraj
                      </Link>
                      <Link
                        className={`dropdown-item text-danger ${
                          theme === "dark" ? "dark-theme-hover" : ""
                        }`}
                        to="#"
                        onClick={(e) => {
                          e.preventDefault();
                          setReqid(user._id);
                          setSubmithandler({ call: removeUserHandler });
                          setModaltitle("Obrisi korisnika");
                          setShowDetails(false);
                          setModalShow(true);
                        }}
                      >
                        <i className="fas fa-solid fa-trash"></i> Obrisi
                      </Link>
                    </div>
                  </div>
                </div>
              )}
              <img
                className="img-md img-avatar"
                src={user?.avatarImage?.url}
                alt="User pic"
              />
            </CardHeader>
            <CardBody section>
              {error && <Message variant="alert-danger">{error}</Message>}
              {loading || removeLoading ? (
                <Loading
                  position="position-absolute"
                  width="100%"
                  height="100%"
                />
              ) : error || removeError ? (
                <Message variant="alert-danger">{error || removeError}</Message>
              ) : (
                <>
                  <h5 className="card-title mt-5">{user.name}</h5>
                  <div className="card-text text-muted">
                    {user.isAdmin === true ? (
                      <p className="m-0">Admin</p>
                    ) : user.isSuperAdmin == true ? (
                      <p className="m-0">Super Admin</p>
                    ) : (
                      <p className="m-0">Korisnik</p>
                    )}

                    <p>
                      <a
                        className={theme === "dark" ? "text-white" : ""}
                        href={`mailto:${user.email}`}
                      >
                        {user.email}
                      </a>
                    </p>
                  </div>
                </>
              )}
            </CardBody>
            <div className="container">
              <TopTotal
                products={products}
                orders={orders?.orders}
                byEmail={user?.email}
              />
            </div>
          </Card>
          {user?.email && (
            <MainProducts byEmail={user.email} isAdmin={userInfo.isAdmin} />
          )}
        </Card>
      </Section>

      <CenteredModal
        title={modalTitle}
        showDetails={showDetails}
        submithandler={submithandler}
        reqid={reqid}
        setshow={setModalShow}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}
