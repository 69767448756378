import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

// Logged-in User Router
export function LogedinRouter() {
  const { userInfo } = useSelector((state) => state.userLogin);

  return userInfo && userInfo.token ? <Outlet /> : <Navigate to="/login" />;
}

// Admin Router
export function AdminRouter() {
  const { userInfo } = useSelector((state) => state.userLogin);

  return userInfo && userInfo.isAdmin ? <Outlet /> : <Navigate to="/login" />;
}

// Super Admin Router
export function SuperAdminRouter() {
  const { userInfo } = useSelector((state) => state.userLogin);

  return userInfo && userInfo.isSuperAdmin ? <Outlet /> : <Navigate to="/login" />;
}

// Both Admin Router
export function BothAdminRouter() {
  const { userInfo } = useSelector((state) => state.userLogin);

  return userInfo && (userInfo.isAdmin || userInfo.isSuperAdmin) ? (
    <Outlet />
  ) : (
    <Navigate to="/login" />
  );
}
