import {
  BLOG_LIST_REQUEST,
  BLOG_LIST_SUCCESS,
  BLOG_LIST_FAIL,
  BLOG_SINGLE_REQUEST,
  BLOG_SINGLE_SUCCESS,
  BLOG_SINGLE_FAIL,
  BLOG_CREATE_REQUEST,
  BLOG_CREATE_SUCCESS,
  BLOG_CREATE_FAIL,
  BLOG_UPDATE_REQUEST,
  BLOG_UPDATE_SUCCESS,
  BLOG_UPDATE_FAIL,
  BLOG_DELETE_REQUEST,
  BLOG_DELETE_SUCCESS,
  BLOG_DELETE_FAIL,
} from "../Constants/BlogConstants";
import { logout } from "./UserActions";
import _axios from "_axios";

// GET BLOG LIST
export const listBlogs =
  ({ pageNumber, keyword, sortBy, toShow }) =>
  async (dispatch) => {
    try {
      dispatch({ type: BLOG_LIST_REQUEST });

      const { data } = await _axios.get(
        `blogs/all?pageNumber=${pageNumber}&keyword=${keyword}&sortBy=${sortBy}&toShow=${toShow}`
      );

      dispatch({ type: BLOG_LIST_SUCCESS, payload: data });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Autorizacija neuspela, nema tokena") {
        dispatch(logout());
      }
      dispatch({
        type: BLOG_LIST_FAIL,
        payload: message,
      });
    }
  };

// GET SINGLE BLOG
export const singleBlog = (id) => async (dispatch) => {
  try {
    dispatch({ type: BLOG_SINGLE_REQUEST });

    const { data } = await _axios.get(`blogs/single/${id}`);

    dispatch({ type: BLOG_SINGLE_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Autorizacija neuspela, nema tokena") {
      dispatch(logout());
    }
    dispatch({
      type: BLOG_SINGLE_FAIL,
      payload: message,
    });
  }
};

// CREATE BLOG
export const createBlog =
  ({ title, richText, image }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: BLOG_CREATE_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          "Content-Type": "application/json",
        },
      };

      const { data } = await _axios.post(
        `blogs/`,
        { title, richText, image },
        config
      );

      dispatch({ type: BLOG_CREATE_SUCCESS, payload: data });
      dispatch(listBlogs({ pageNumber: 1, keyword: "" }));
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Autorizacija neuspela, nema tokena") {
        dispatch(logout());
      }
      dispatch({
        type: BLOG_CREATE_FAIL,
        payload: message,
      });
    }
  };

// EDIT BLOG
export const updateBlog =
  ({ id, title, richText, image, imageToDelete }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: BLOG_UPDATE_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          "Content-Type": "application/json",
        },
      };

      const { data } = await _axios.put(
        `blogs/${id}`,
        { title, richText, image, imageToDelete },
        config
      );
      dispatch({ type: BLOG_UPDATE_SUCCESS, payload: data });
      dispatch(listBlogs({ pageNumber: 1, keyword: "" }));
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Autorizacija neuspela, nema tokena") {
        dispatch(logout());
      }
      dispatch({
        type: BLOG_UPDATE_FAIL,
        payload: message,
      });
    }
  };

// DELETE BLOG
export const deleteBlog = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: BLOG_DELETE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await _axios.delete(`blogs/${id}`, config);

    dispatch({ type: BLOG_DELETE_SUCCESS });
    dispatch(listBlogs({ pageNumber: 1, keyword: "" }));
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Autorizacija neuspela, nema tokena") {
      dispatch(logout());
    }
    dispatch({
      type: BLOG_DELETE_FAIL,
      payload: message,
    });
  }
};
