import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Message from "../../components/client/loading-error/Error";
import Loading from "../../components/client/loading-error/Loading";
import Header from "../../components/client/Header";
import { resetPassword } from "../../Redux/client/Actions/UserActions";
import Button from "../../components/client/ui/Button";
import Input from "../../components/client/ui/form/Input";
import { useNavigate } from "react-router";

export default function ResetPassword({ location }) {
  window.scrollTo(0, 0);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const redirect = location.search ? location.search.split("=")[1] : "/";

  const userResetPassword = useSelector((state) => state.userResetPassword);
  const { error, loading, message } = userResetPassword;

  useEffect(() => {
    if (message) {
      navigate(redirect);
    }
  }, [message, navigate, redirect]);

  const submitHandler = (e) => {
    e.preventDefault();
    const pathArray = location.pathname.split("/");
    dispatch(
      resetPassword({ password, userId: pathArray[2], token: pathArray[3] })
    );
  };

  return (
    <>
      <Header />
      <div className="container d-flex flex-column justify-content-center align-items-center login-center">
        {loading && <Loading />}
        <div className="Login col-md-8 col-lg-4 col-11">
          {error && (
            <Message className="w-100" variant="alert-danger">
              {error}
            </Message>
          )}
          <form onSubmit={submitHandler}>
            <Input
              inputClass="login-input"
              type="password"
              placeholder="Nova lozinka"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Input
              inputClass="login-input"
              type="password"
              placeholder="Potvrdi lozinku"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <Button
              type="submit"
              disabled={
                password !== confirmPassword || !password || !confirmPassword
              }
            >
              Potvrdi
            </Button>
          </form>
        </div>
      </div>
    </>
  );
}
