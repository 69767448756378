import React from "react";
import Sidebar from "./../../components/dashboard/Sidebar";
import Header from "./../../components/dashboard/Header";
import EditProductMain from "./../../components/dashboard/products/EditproductMain";
import PageWrapper from "../../components/dashboard/common/PageWapper";
import { useParams } from "react-router";

const ProductEditScreen = () => {
  const { id: productId } = useParams();
  return (
    <>
      <Sidebar />
      <PageWrapper>
        <Header />
        <EditProductMain productId={productId} />
      </PageWrapper>
    </>
  );
};
export default ProductEditScreen;
