import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Message from "../../components/client/loading-error/Error";
import Loading from "../../components/client/loading-error/Loading";
import Header from "../../components/client/Header";
import { sendLink } from "../../Redux/client/Actions/UserActions";
import Button from "../../components/client/ui/Button";
import Input from "../../components/client/ui/form/Input";

export default function ForgotPassword() {
  window.scrollTo(0, 0);
  const [email, setEmail] = useState("");

  const dispatch = useDispatch();
  // const redirect = location.search ? location.search.split("=")[1] : "/";

  const userSendLink = useSelector((state) => state.userSendLink);
  const { error, loading, message } = userSendLink;

  // useEffect(() => {
  //   if (userInfo) {
  //     navigate(redirect);
  //   }
  // }, [userInfo, navigate, redirect]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(sendLink({ email }));
  };

  return (
    <>
      <Header />
      <div className="container d-flex flex-column justify-content-center align-items-center login-center">
        {loading && <Loading />}
        <div className="Login col-md-8 col-lg-4 col-11">
          {message && (
            <Message className="w-100" variant="alert-success">
              {message}
            </Message>
          )}
          {error && (
            <Message className="w-100" variant="alert-danger">
              {error}
            </Message>
          )}
          <form
            onSubmit={submitHandler}
          >
            <Input
              label="Unesite vasu email adresu"
              type="email"
              placeholder="Email"
              required={true}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Button type="submit">Posalji Link</Button>
          </form>
        </div>
      </div>
    </>
  );
}
