import { useDispatch, useSelector } from "react-redux";
import Header from "../../components/client/Header";
import Loading from "../../components/client/loading-error/Loading";
import Message from "../../components/client/loading-error/Error";
import { useEffect } from "react";
import { singleBlog } from "../../Redux/dashboard/Actions/BlogActions";
import parse from "html-react-parser";
import Footer from "../../components/client/Footer";
import { useNavigate, useParams } from "react-router";

export default function SingleBlog() {
  const dispatch = useDispatch();

  const { id: blogId } = useParams();

  const navigate = useNavigate();

  const blogSingle = useSelector((state) => state.blogSingle);
  const { loading, error, blog } = blogSingle;

  const themeData = useSelector((state) => state.settings);
  const { loading: loadingSettings, error: errorSettings, theme } = themeData;

  useEffect(() => {
    if (dispatch && blogId) {
      dispatch(singleBlog(blogId));
    }
  }, [dispatch, blogId]);

  return (
    <>
      <Header />
      <div className="container single-blog py-lg-3 py-md-3 py-0 mb-4">
        {loading || loadingSettings ? (
          <Loading />
        ) : error || errorSettings ? (
          <Message variant="alert-danger">{error || errorSettings}</Message>
        ) : (
          <>
            <div className="p-0 mb-2">
              <button
                className={`btn border-${theme}-200 text-${theme}`}
                onClick={() => navigate(-1)}
              >
                <i className="fas fa-arrow-left"></i> Nazad
              </button>
            </div>

            <h1 className="text-center text-uppercase mt-4">{blog?.title}</h1>
            <div className="blog-image-wrapper m-auto w-50 d-flex justify-content-center">
              <img
                style={{ maxWidth: "100%" }}
                src={blog?.image.src}
                alt={blog?.title}
              />
            </div>

            <div className="blog-content">{parse(blog?.richText || "")}</div>
          </>
        )}
      </div>
      <Footer />
    </>
  );
}
