import React, { useEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { listBrands } from "../../../Redux/dashboard/Actions/BrandActions";
import { useNavigate } from "react-router-dom"; // Correct import for React Router v6

export default function BrandCarousel() {
  const brandsData = useSelector((state) => state.brandList);
  const { brands } = brandsData;

  const settingsData = useSelector((state) => state.settings);
  const { theme } = settingsData;

  const dispatch = useDispatch();

  const navigate = useNavigate(); // Replacing useHistory with useNavigate

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 8,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 6,
    },
    tablet: {
      breakpoint: { max: 1024, min: 600 },
      items: 4,
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 2,
    },
  };

  const brandClickHandler = (value) => {
    navigate(`/shop-brand/${value}`);
  };

  useEffect(() => {
    if (dispatch) {
      dispatch(listBrands({ pageNumber: 1, keyword: "" }));
    }
  }, [dispatch]);

  return (
    <>
      {brands && !isEmpty(brands.brands) && (
        <div className="site-bg py-5">
          <h3 className="text-center text-gray">BRENDOVI</h3>
          <div className="container py-2 px-0 site-bg mb-5 mt-3">
            {brands && (
              <Carousel
                className="p-0"
                infinite={true}
                autoPlay={true}
                renderButtonGroupOutside={true}
                responsive={responsive}
                arrows={false}
              >
                {brands.brands.map((brand, index) => (
                  <div
                    className="brand p-2"
                    key={index}
                    onClick={() => brandClickHandler(brand.title)}
                  >
                    <div
                      className={`brand-content pointer d-flex flex-grow-1 flex-column border-1 border-transition-hover-${theme}-500`}
                    >
                      <div className="image-holder h-50 d-flex flex-grow-1 justify-content-center align-items-center">
                        <img className="img-fluid" src={brand.image.src} alt={brand.title} />
                      </div>
                      <h3 className="text-holder text-gray pb-1 d-flex flex-grow-1 justify-content-center align-items-end">
                        Shop
                      </h3>
                    </div>
                  </div>
                ))}
              </Carousel>
            )}
          </div>
        </div>
      )}
    </>
  );
}
