import { useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill-new"; // Use the official react-quill package
import "react-quill-new/dist/quill.snow.css"; // Import Quill's default styles

export default function TextEditor({ onChange, className, label, value }) {
  const [localValue, setLocalValue] = useState(value || "");

  const quillRef = useRef(null);

  // Toolbar configuration
  const toolbarOptions = [
    ["bold", "italic", "underline", "strike"], // Basic formatting buttons
    ["blockquote", "code-block"], // Block-level formatting
    ["link", "image", "video", "formula"], // Rich content options
    [{ header: 1 }, { header: 2 }], // Headers
    [{ list: "ordered" }, { list: "bullet" }], // Lists
    [{ script: "sub" }, { script: "super" }], // Subscript/superscript
    [{ indent: "-1" }, { indent: "+1" }], // Indentations
    [{ direction: "rtl" }], // Text direction
    [{ size: ["small", false, "large", "huge"] }], // Font sizes
    [{ header: [1, 2, 3, 4, 5, 6, false] }], // Header levels
    [{ color: [] }, { background: [] }], // Color options
    [{ font: [] }], // Font styles
    [{ align: [] }], // Text alignment
    ["clean"], // Clear formatting
  ];

  // Quill editor modules
  const modules = {
    toolbar: toolbarOptions,
  };

  // Quill editor formats
  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "code-block",
    "link",
    "image",
    "video",
    "formula",
    "list",
    "bullet", // Bullet format registered here
    "script",
    "indent",
    "direction",
    "size",
    "color",
    "background",
    "font",
    "align",
  ];

  // Sync value prop with local state
  useEffect(() => {
    if (value) {
      setLocalValue(value);
    }
  }, [value]);

  return (
    <div className={className}>
      {label && <label className="form-label">{label}</label>}
      <ReactQuill
        ref={quillRef}
        value={localValue}
        modules={modules}
        formats={formats}
        onChange={(value) => {
          setLocalValue(value);
          onChange(value);
        }}
      />
    </div>
  );
}
