import React from "react";
import { useParams } from "react-router-dom";
import Sidebar from "./../../components/dashboard/Sidebar";
import Header from "./../../components/dashboard/Header";
import OrderDetailmain from "../../components/dashboard/orders/OrderDetailmain";
import PageWrapper from "../../components/dashboard/common/PageWapper";

const OrderDetailScreen = () => {
  const { id: orderId } = useParams();  // This will give you the `id` parameter
  return (
    <>
      <Sidebar />
      <PageWrapper>
        <Header />
        <OrderDetailmain orderId={orderId} />
      </PageWrapper>
    </>
  );
};

export default OrderDetailScreen;
