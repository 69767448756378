import _axios from "_axios";
import {
  PRODUCT_CREATE_REVIEW_FAIL,
  PRODUCT_CREATE_REVIEW_REQUEST,
  PRODUCT_CREATE_REVIEW_SUCCESS,
  PRODUCT_DETAILS_FAIL,
  PRODUCT_DETAILS_REQUEST,
  PRODUCT_DETAILS_SUCCESS,
  PRODUCT_HIGHEST_PRICE_FAIL,
  PRODUCT_HIGHEST_PRICE_REQUEST,
  PRODUCT_HIGHEST_PRICE_SUCCESS,
  PRODUCT_LIST_FAIL,
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
  PRODUCT_FOLLOWER_FAIL,
  PRODUCT_FOLLOWER_REQUEST,
  PRODUCT_FOLLOWER_SUCCESS,
  PRODUCT_PREVIEW,
  PRODUCT_LIST_HOME_REQUEST,
  PRODUCT_LIST_HOME_SUCCESS,
  PRODUCT_LIST_HOME_FAIL,
  PRODUCT_SEARCH_REQUEST,
  PRODUCT_SEARCH_SUCCESS,
  PRODUCT_SEARCH_FAIL,
  FAVORITE_LIST_REQUEST,
  FAVORITE_LIST_SUCCESS,
  FAVORITE_LIST_FAIL,
} from "../Constants/ProductConstants";
import { logout } from "./UserActions";
import { listSimilarProducts } from "../../dashboard/Actions/ProductActions";

// PRODUCT LIST
export const listProduct =
  ({
    pageNumber,
    keyword,
    slug,
    brands,
    materials,
    sellerId,
    min,
    max,
    toShow,
    sortBy,
  }) =>
  async (dispatch) => {
    try {
      dispatch({ type: PRODUCT_LIST_REQUEST });
      const { data } = await _axios.get(
        `/products?keyword=${keyword}&pageNumber=${pageNumber}&slug=${slug}&sellerId=${sellerId}&toShow=${toShow}&sortBy=${sortBy}&min=${min}&max=${max}&brands=${brands}&materials=${materials}`
      );
      dispatch({ type: PRODUCT_LIST_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: PRODUCT_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

// PRODUCT LIST FOR SEARCH IN HEADER
export const listProductSearch =
  ({ keyword }) =>
  async (dispatch) => {
    try {
      dispatch({ type: PRODUCT_SEARCH_REQUEST });
      const { data } = await _axios.get(`/products/search?keyword=${keyword}`);
      dispatch({ type: PRODUCT_SEARCH_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: PRODUCT_SEARCH_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

// ALL PRODUCT LIST
export const listProductAll = () => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_LIST_REQUEST });
    const { data } = await _axios.get(`/products/all`);
    dispatch({ type: PRODUCT_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: PRODUCT_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// HOME PRODUCT LIST
export const listHomeProductAll = () => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_LIST_HOME_REQUEST });
    const { data } = await _axios.get(`/products/home`);
    dispatch({ type: PRODUCT_LIST_HOME_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: PRODUCT_LIST_HOME_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// HIGHEST PRODUCT PRICE
export const highestProductPrice = () => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_HIGHEST_PRICE_REQUEST });
    const { data } = await _axios.get(`/products/highest_price`);
    dispatch({ type: PRODUCT_HIGHEST_PRICE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: PRODUCT_HIGHEST_PRICE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// SINGLE PRODUCT
export const listProductDetails = (id) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    dispatch({ type: PRODUCT_DETAILS_REQUEST });
    const { data } = await _axios.post(
      `/products/${id}/client`,
      { isSuperAdmin: userInfo?.isSuperAdmin || false },
      config
    );
    dispatch({ type: PRODUCT_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: PRODUCT_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// PRODUCT REVIEW CREATE
export const createProductReview =
  (productId, review) => async (dispatch, getState) => {
    try {
      dispatch({ type: PRODUCT_CREATE_REVIEW_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      await _axios.post(`/products/${productId}/review`, review, config);
      dispatch({ type: PRODUCT_CREATE_REVIEW_SUCCESS });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Autorizacija neuspela, nema tokena") {
        dispatch(logout());
      }
      dispatch({
        type: PRODUCT_CREATE_REVIEW_FAIL,
        payload: message,
      });
    }
  };

// PRODUCT ADD FOLLOWER
export const addProductFollower = (productId, follower) => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_FOLLOWER_REQUEST });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    await _axios.post(`/products/${productId}/follow`, follower, config);
    dispatch({ type: PRODUCT_FOLLOWER_SUCCESS });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: PRODUCT_FOLLOWER_FAIL,
      payload: message,
    });
  }
};

// LIKE PRODUCT
export const likeProduct =
  ({ productId }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: PRODUCT_FOLLOWER_REQUEST });

      const {
        userLogin: { userInfo },
        clientProductList: { page, prevKeyword, prevSlug, toShow, sort },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      await _axios.put(
        `/products/${productId}/like`,
        { likerId: userInfo._id },
        config
      );
      dispatch({ type: PRODUCT_FOLLOWER_SUCCESS });
      dispatch(
        listProduct({
          pageNumber: page,
          keyword: prevKeyword,
          slug: prevSlug,
          toShow,
          sortBy: sort,
        })
      );
      dispatch(favoriteList());
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: PRODUCT_FOLLOWER_FAIL,
        payload: message,
      });
    }
  };

// LIKE PRODUCT FROM CAROUSEL
export const likeProductFromCarousel =
  ({ productId, similarProducts }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: PRODUCT_FOLLOWER_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      await _axios.put(
        `/products/${productId}/like`,
        { likerId: userInfo._id },
        config
      );
      dispatch({ type: PRODUCT_FOLLOWER_SUCCESS });
      dispatch(listHomeProductAll());
      dispatch(favoriteList());

      if (similarProducts) {
        dispatch(listSimilarProducts({ similarProducts }));
      }
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: PRODUCT_FOLLOWER_FAIL,
        payload: message,
      });
    }
  };

// FAVORITE PRODUCTS LIST
export const favoriteList = () => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    dispatch({ type: FAVORITE_LIST_REQUEST });
    const { data } = await _axios.get(
      `/products/${userInfo._id}/favorite`,
      config
    );
    dispatch({ type: FAVORITE_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: FAVORITE_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// PRODUCT PREVIEW
export const productPreview = (preview) => async (dispatch) => {
  dispatch({ type: PRODUCT_PREVIEW, payload: preview });
  localStorage.setItem("productPreview", preview);
};
