import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../components/client/Header";
import { saveShippingAddress } from "../../Redux/client/Actions/cartActions";
import Button from "../../components/client/ui/Button";
import Input from "../../components/client/ui/form/Input";
import { useNavigate } from "react-router";

const ShippingScreen = () => {
  window.scrollTo(0, 0);

  const navigate = useNavigate();

  const cart = useSelector((state) => state.cart);
  const { shippingAddress } = cart;

  const settingsData = useSelector((state) => state.settings);
  const { theme } = settingsData;

  const [fullName, setFullName] = useState(shippingAddress.fullName);
  const [email, setEmail] = useState(shippingAddress.email);
  const [phoneNumber, setPhoneNumber] = useState(shippingAddress.phoneNumber);
  const [address, setAddress] = useState(shippingAddress.address);
  const [city, setCity] = useState(shippingAddress.city);
  const [postalCode, setPostalCode] = useState(shippingAddress.postalCode);
  const [country, setCountry] = useState(shippingAddress.country);

  const dispatch = useDispatch();

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      saveShippingAddress({
        fullName,
        email,
        phoneNumber,
        address,
        city,
        postalCode,
        country,
      })
    );
    navigate("/placeorder");
  };
  return (
    <>
      <Header />
      <div className="container d-flex justify-content-center align-items-center login-center">
        <div className="col-md-8 col-lg-4 col-11">
          <div className="p-0 mb-2">
            <Button
              variant="outline"
              className="w-auto"
              onClick={() => navigate(-1)}
            >
              <i className="fas fa-arrow-left"></i> Nazad
            </Button>
          </div>
          <form className="Login" onSubmit={submitHandler}>
            <h6 className="text-gray">PODACI O DOSTAVI</h6>
            <Input
              type="text"
              placeholder="Ime i Prezime"
              inputClass="py-3 my-2"
              value={fullName}
              required
              onChange={(e) => setFullName(e.target.value)}
            />
            <Input
              type="email"
              placeholder="Email"
              inputClass="py-3 mb-2"
              value={email}
              required
              onChange={(e) => setEmail(e.target.value)}
            />
            <Input
              type="number"
              placeholder="Broj Telefona"
              inputClass="py-3 mb-2"
              value={phoneNumber}
              required
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
            <Input
              type="text"
              placeholder="Adresa"
              inputClass="py-3 mb-2"
              value={address}
              required
              onChange={(e) => setAddress(e.target.value)}
            />
            <Input
              type="text"
              placeholder="Grad"
              inputClass="py-3 mb-2"
              value={city}
              required
              onChange={(e) => setCity(e.target.value)}
            />
            <Input
              type="text"
              placeholder="Postanski broj"
              inputClass="py-3 mb-2"
              value={postalCode}
              required
              onChange={(e) => setPostalCode(e.target.value)}
            />
            <Input
              type="text"
              placeholder="Zemlja"
              inputClass="py-3 mb-2"
              value={country}
              required
              onChange={(e) => setCountry(e.target.value)}
            />
            <Button className="p-3" type="submit">
              Nastavite
            </Button>
          </form>
        </div>
      </div>
    </>
  );
};

export default ShippingScreen;
