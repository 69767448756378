import { configureStore } from "@reduxjs/toolkit";
import { thunk } from "redux-thunk";
import {
  clientProductListReducer,
  clientProductListDiscountReducer,
  productSearchReducer,
  productLikeReducer,
  favoriteReducer,
  highestPriceReducer,
  clientCategoriesListReducer,
  productDetailsReducer,
  productCreateReviewReducer,
  productFollowerReducer,
  productPreviewReducer,
  cartReducer,
  userLoginReducer,
  userRegisterReducer,
  userSendLinkReducer,
  userResetPasswordReducer,
  userDetailsReducer,
  userUpdateProfileReducer,
  orderCreateReducer,
  clientOrderDetailsReducer,
  orderPayReducer,
  orderListMyReducer,
  addEmailReducer,
  clientBlogListReducer,
  blogPreviewReducer,
  todoCheckReducer,
} from "./client/Reducers";

import {
  themeReducer,
  createUserReducer,
  userListReducer,
  singleUserReducer,
  blockUserReducer,
  removeUserReducer,
  editUserReducer,
  productCreateReducer,
  productDeleteReducer,
  productEditReducer,
  productListReducer,
  productUpdateReducer,
  productSimilarListReducer,
  brandListReducer,
  categoriesListReducer,
  orderListReducer,
  cloudinaryUsageReducer,
  settingsReducer,
  todoListDoneReducer,
  materialListReducer,
  categoryEditReducer,
  categoryCreateReducer,
  checkAvailabilityReducer,
  brandCreateReducer,
  brandEditReducer,
  brandDeleteReducer,
  materialCreateReducer,
  materialEditReducer,
  materialDeleteReducer,
  blogSingleReducer,
  blogListReducer,
  blogCreateReducer,
  blogUpdateReducer,
  blogDeleteReducer,
  orderDetailsReducer,
  orderComplaintReducer,
  todoListPendingReducer,
  todoAddReducer,
  todoDeleteReducer,
  todoDoneReducer,
  // onlineUsersListReducer,
} from "./dashboard/Reducers";

// Combine all reducers into a single object
const rootReducer = {
  clientProductList: clientProductListReducer,
  clientProductListDiscount: clientProductListDiscountReducer,
  productSearch: productSearchReducer,
  productLike: productLikeReducer,
  favorite: favoriteReducer,
  highestPrice: highestPriceReducer,
  clientCategoriesList: clientCategoriesListReducer,
  productDetails: productDetailsReducer,
  productReviewCreate: productCreateReviewReducer,
  productFollower: productFollowerReducer,
  productPreview: productPreviewReducer,
  cart: cartReducer,
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userSendLink: userSendLinkReducer,
  userResetPassword: userResetPasswordReducer,
  userDetails: userDetailsReducer,
  userUpdateProfile: userUpdateProfileReducer,
  orderCreate: orderCreateReducer,
  clientOrderDetails: clientOrderDetailsReducer,
  orderPay: orderPayReducer,
  orderListMy: orderListMyReducer,
  addEmail: addEmailReducer,
  clientBlogList: clientBlogListReducer,
  blogPreview: blogPreviewReducer,
  todoCheck: todoCheckReducer,
  brandList: brandListReducer,
  categoriesList: categoriesListReducer,
  theme: themeReducer,
  createUser: createUserReducer,
  blockUser: blockUserReducer,
  removeUser: removeUserReducer,
  editUser: editUserReducer,
  userList: userListReducer,
  singleUser: singleUserReducer,
  productList: productListReducer,
  productSimilarList: productSimilarListReducer,
  productDelete: productDeleteReducer,
  productCreate: productCreateReducer,
  productEdit: productEditReducer,
  productUpdate: productUpdateReducer,
  orderList: orderListReducer,
  cloudinaryUsage: cloudinaryUsageReducer,
  settings: settingsReducer,
  todoListDone: todoListDoneReducer,
  todoListPending: todoListPendingReducer,
  todoAdd: todoAddReducer,
  todoDelete: todoDeleteReducer,
  todoDone: todoDoneReducer,
  materialList: materialListReducer,
  categoryEdit: categoryEditReducer,
  categoryCreate: categoryCreateReducer,
  checkAvailability: checkAvailabilityReducer,
  brandCreate: brandCreateReducer,
  brandEdit: brandEditReducer,
  brandDelete: brandDeleteReducer,
  materialCreate: materialCreateReducer,
  materialEdit: materialEditReducer,
  materialDelete: materialDeleteReducer,
  blogList: blogListReducer,
  blogSingle: blogSingleReducer,
  blogCreate: blogCreateReducer,
  blogUpdate: blogUpdateReducer,
  blogDelete: blogDeleteReducer,
  orderDetails: orderDetailsReducer,
  orderComplaint: orderComplaintReducer,
  // onlineUsersList: onlineUsersListReducer,
};

// Initial state from localStorage
const initialState = {
  cart: {
    cartItems: JSON.parse(localStorage.getItem("cartItems")) || [],
    shippingAddress: JSON.parse(localStorage.getItem("shippingAddress")) || {},
  },
  userLogin: { userInfo: JSON.parse(localStorage.getItem("userInfo")) || null },
  productPreview: { preview: localStorage.getItem("productPreview") || "grid" },
  blogPreview: { preview: localStorage.getItem("blogPreview") || "grid" },
  settings: JSON.parse(localStorage.getItem("settings")) || { theme: "orange" },
  theme: { theme: JSON.parse(localStorage.getItem("theme")) || "dark" },
};

// Configure the store using Redux Toolkit
const store = configureStore({
  reducer: rootReducer,
  preloadedState: initialState,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
  devTools: process.env.NODE_ENV !== "production",
});

export default store;
