import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { createOrder } from "../../Redux/client/Actions/OrderActions";
import { ORDER_CREATE_RESET } from "../../Redux/client/Constants/OrderConstants";
import Header from "../../components/client/Header";
import Message from "../../components/client/loading-error/Error";
import Button from "../../components/client/ui/Button";

const PlaceOrderScreen = () => {
  window.scrollTo(0, 0);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const cart = useSelector((state) => state.cart);
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  // Calculate Price
  const addDecimals = (num) => {
    return (Math.round(num * 100) / 100).toFixed(2);
  };

  cart.itemsPrice = addDecimals(
    cart.cartItems.reduce(
      (acc, item) => acc + (item.discountPrice || item.price) * item.qty,
      0
    )
  );
  cart.shippingPrice = addDecimals(cart.itemsPrice > 100 ? 0 : 100);
  cart.taxPrice = addDecimals(Number((0.15 * cart.itemsPrice).toFixed(2)));
  cart.totalPrice = Number(cart.itemsPrice)
    // Number(cart.shippingPrice) +
    // Number(cart.taxPrice)
    .toFixed(2);

  const sortedOrderBySeller = useMemo(() => {
    return cart.cartItems.reduce((accumulator, item) => {
      accumulator[item.seller.email] = {
        cartItems: cart.cartItems.filter(
          (n) => n.seller.email === item.seller.email
        ),
        shippingAddress: { ...cart.shippingAddress },
        shippingPrice: cart.shippingPrice,
        taxPrice: cart.taxPrice,
        totalPrice: cart.cartItems.reduce((acc, n) => {
          if (n.seller.email === item.seller.email) {
            acc += n.discountPrice || n.price;
          }

          return acc;
        }, 0),
      };

      return accumulator;
    }, {});
  }, [cart]);

  const orderCreate = useSelector((state) => state.orderCreate);
  const { order, success, error } = orderCreate;

  const settingsData = useSelector((state) => state.settings);
  const { theme } = settingsData;

  const placeOrderHandler = () => {
    for (const key in sortedOrderBySeller) {
      dispatch(
        createOrder({
          user: userInfo,
          seller: sortedOrderBySeller[key].cartItems[0].seller,
          orderItems: sortedOrderBySeller[key].cartItems,
          shippingAddress: sortedOrderBySeller[key].shippingAddress,
          paymentMethod: "Placanje pouzecem",
          itemsPrice: sortedOrderBySeller[key].itemsPrice,
          shippingPrice: sortedOrderBySeller[key].shippingPrice,
          taxPrice: sortedOrderBySeller[key].taxPrice,
          totalPrice: sortedOrderBySeller[key].totalPrice,
          isNewOrder: true,
        })
      );
    }
  };

  useEffect(() => {
    if (success) {
      navigate(`/order/${order._id}`);
      navigate(`/`);
      dispatch({ type: ORDER_CREATE_RESET });
    }
  }, [navigate, dispatch, success, order]);

  return (
    <>
      <Header />
      <div className="container pt-2">
        <div className="p-0 mb-2">
          <Button variant="outline" onClick={() => navigate(-1)}>
            <i className="fas fa-arrow-left"></i> Nazad
          </Button>
        </div>
        <div className={`row bg-${theme}-100 order-detail`}>
          <div className="col-lg-4 col-sm-4 mb-lg-4 mb-5 mb-sm-0">
            <div className="row ">
              <div className="col-md-4 center">
                <div className={`bg-${theme}-300 text-${theme}-700 order-box`}>
                  <i className="fas fa-user"></i>
                </div>
              </div>
              <div className="col-md-8 center text-gray">
                <h5>
                  <strong>Korisnik</strong>
                </h5>
                <p>{userInfo?.name}</p>
                <p>{userInfo?.email}</p>
              </div>
            </div>
          </div>
          {/* 2 */}
          <div className="col-lg-4 col-sm-4 mb-lg-4 mb-5 mb-sm-0">
            <div className="row">
              <div className="col-md-4 center">
                <div className={`bg-${theme}-300 text-${theme}-700 order-box`}>
                  <i className="fas fa-truck-moving"></i>
                </div>
              </div>
              <div className="col-md-8 center text-gray">
                <h5>
                  <strong>Informacije o dostavi</strong>
                </h5>
                <p>
                  <strong>Puno Ime:</strong> {cart.shippingAddress.fullName}
                </p>
                <p>
                  <strong>Email:</strong> {cart.shippingAddress.email}
                </p>
                <p>
                  <strong>Broj telefona:</strong>{" "}
                  {cart.shippingAddress.phoneNumber}
                </p>
                <p>
                  <strong>Metod placanja:</strong> Placanje pouzecem
                </p>
                <p>
                  <strong>Dostava:</strong> {cart.shippingAddress.country}
                </p>
              </div>
            </div>
          </div>
          {/* 3 */}
          <div className="col-lg-4 col-sm-4 mb-lg-4 mb-5 mb-sm-0">
            <div className="row">
              <div className="col-md-4 center">
                <div className={`bg-${theme}-300 text-${theme}-700 order-box`}>
                  <i className="fas fa-map-marker-alt"></i>
                </div>
              </div>
              <div className="col-md-8 center text-gray">
                <h5>
                  <strong>Dostaviti na adresu</strong>
                </h5>
                <p>
                  <strong>Adresa:</strong> {cart.shippingAddress.city},{" "}
                  {cart.shippingAddress.address},{" "}
                  {cart.shippingAddress.postalCode}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="row order-products justify-content-between">
          <div className="col-lg-8">
            {cart.cartItems.length === 0 ? (
              <Message variant={`bg-${theme}-100 mt-5`}>
                Vasa korpa je prazna
              </Message>
            ) : (
              <>
                {cart.cartItems.map((item, index) => (
                  <div
                    className={`order-product border-bottom-1-${theme}-200 row`}
                    key={index}
                  >
                    <div className="col-3">
                      <img src={item.images[0].src} alt={item.name} />
                    </div>
                    <div className="col-md-3 col-6 d-flex align-items-center text-gray">
                      <Link to={`/products/${item.product}`}>
                        <h6>{item.name}</h6>
                      </Link>
                    </div>
                    {/* {item.dimensions && (
                      <div className="mt-3 mt-md-0 col-md-2 col-6  d-flex align-items-center flex-column justify-content-center">
                        {item.dimensions && (
                          <>
                            <h4>Dimenzije</h4>
                            <h6>{`W:${item.dimensions?.width} / L:${item.dimensions?.length} / H:${item.dimensions?.height} cm`}</h6>
                          </>
                        )}
                      </div>
                    )} */}
                    <div className="mt-3 mt-md-0 col-md-3 col-6  d-flex align-items-center flex-column justify-content-center ">
                      <h4>KOLICINA</h4>
                      <h6>{item.qty}</h6>
                    </div>
                    <div className="mt-3 mt-md-0 col-md-3 col-6 align-items-end  d-flex flex-column justify-content-center ">
                      <h4 className="text-end">UKUPNO ZA PROIZVODE</h4>
                      <h6>
                        {item.qty * (item.discountPrice || item.price)} RSD
                      </h6>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
          {/* total */}
          <div className="col-lg-3 d-flex align-items-end flex-column mt-5 subtotal-order text-gray">
            <table className="table table-bordered mb-0">
              <tbody>
                <tr>
                  <td>
                    <strong>Proizvodi</strong>
                  </td>
                  <td>{cart.itemsPrice} RSD</td>
                </tr>
                <tr>
                  <td>
                    <strong>Dostava</strong>
                  </td>
                  <td>{cart.shippingPrice} RSD</td>
                </tr>
                <tr>
                  <td>
                    <strong>UKUPNO</strong>
                  </td>
                  <td>{cart.totalPrice} RSD</td>
                </tr>
              </tbody>
            </table>
            {cart.cartItems.length === 0 ? null : (
              <Button type="submit" onClick={placeOrderHandler}>
                PORUCITE
              </Button>
            )}
            {error && (
              <div className="my-3 col-12">
                <Message variant="alert-danger">{error}</Message>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PlaceOrderScreen;
