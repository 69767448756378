import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../Redux/client/Actions/UserActions";
import MobileMenu from "./MobileMenu";
import CartPopover from "./CartPopover";
import DesktopMenu from "./DesktopMenu";
import { Cart } from "./common/BootstrapIcons";
import Search from "./ui/Search";
import { isEmpty } from "lodash";
import { favoriteList } from "../../Redux/client/Actions/ProductActions";

const Favorite = () => {
  const settingsData = useSelector((state) => state.settings);
  const { theme } = settingsData;

  const favorite = useSelector((state) => state.favorite);
  const { products } = favorite;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(favoriteList());
  }, [dispatch]);

  return (
    <Link
      to="/favorite"
      style={{ fontSize: "20px" }}
      className={`position-relative d-flex align-items-center mx-2 text-hover-${theme}-500 text-${theme}-500`}
    >
      {!isEmpty(products) ? (
        <>
          <span
            style={{ top: "-20px", left: "-5px" }}
            className="num-likes small text-gray position-absolute"
          >
            {products?.length}
          </span>
          <i className="fas fa-heart"></i>
        </>
      ) : (
        <i className="far fa-heart"></i>
      )}
    </Link>
  );
};

export default function Header() {
  const [isHeaderSticky, setIsHeaderSticky] = useState(false);

  const dispatch = useDispatch();

  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const settingsData = useSelector((state) => state.settings);
  const { theme, logo, disableShop } = settingsData;

  const logoutHandler = () => {
    dispatch(logout());
    window.location.reload();
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 150) {
        setIsHeaderSticky(true);
      } else {
        setIsHeaderSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {/* Header */}
      <div className="header site-bg">
        <div className="container h-100">
          {/* MOBILE HEADER */}
          <div className="mobile-header d-block d-lg-none">
            <div className="container px-0">
              <div className="row">
                <div className="col-2 px-0 d-flex align-items-center">
                  <MobileMenu />
                </div>
                <div className="col-2 d-flex align-items-center">
                  {(userInfo?.isAdmin || userInfo?.isSuperAdmin) && (
                    <div className="go-to-dashboard">
                      <Link to="/dashboard">
                        <i className="fal fa-toolbox"></i>
                      </Link>
                    </div>
                  )}
                </div>
                <div className="col-4 d-flex align-items-center content-center">
                  <Link className="navbar-brand d-flex content-center" to="/">
                    <img alt="logo" src={logo && logo[0].src} height={70} />
                  </Link>
                </div>

                <div className="col-4 px-0 d-flex align-items-center justify-content-end Login-Register">
                  {userInfo ? (
                    <div className="btn-group dropstart">
                      <button
                        type="button"
                        className={`dropdown-toggle site-bg border-1 border-focus-${theme}-500`}
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i className="fas fa-user"></i>
                      </button>
                      <div
                        style={{ minWidth: "auto" }}
                        className="dropdown-menu header-popup shadow"
                      >
                        <Link className="dropdown-item bg-white" to="/profile">
                          <i className="fal fa-user"></i> Profil
                        </Link>

                        <Link
                          className="dropdown-item bg-white"
                          to="#"
                          onClick={logoutHandler}
                        >
                          <i className="fal fa-sign-out-alt"></i> Odjava
                        </Link>
                      </div>
                    </div>
                  ) : (
                    <div className="btn-group dropstart">
                      <button
                        type="button"
                        className={`dropdown-toggle border-1 border-focus-${theme}-500`}
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i className="fas fa-user"></i>
                      </button>
                      <div
                        style={{ minWidth: "auto" }}
                        className="dropdown-menu header-popup shadow"
                      >
                        <Link
                          className={`dropdown-item text-${theme}-500`}
                          to="/login"
                        >
                          Prijava
                        </Link>

                        <Link
                          className={`dropdown-item text-${theme}-500`}
                          to="/register"
                        >
                          Registracija
                        </Link>
                      </div>
                    </div>
                  )}
                  <Favorite />
                  {!disableShop && (
                    <Link to="/cart" className="cart-mobile-icon px-2">
                      <Cart width={30} height={30} color="gray" />
                      <span className={`badge bg-${theme}-500`}>
                        {cartItems?.length}
                      </span>
                    </Link>
                  )}
                </div>
                <div className="col-12 px-0 align-items-center">
                  <form className="input-group d-flex align-items-center w-100">
                    <Search />
                  </form>
                </div>
              </div>
            </div>
          </div>

          {/* PC HEADER */}
          <div className="pc-header h-100 items-center d-none d-lg-flex">
            <div className="row h-100 w-100">
              <div className="pc-logo relative col-md-3 col-4 d-flex align-items-center">
                <Link className="navbar-brand w-auto" to="/">
                  <img height={110} alt="logo" src={logo && logo[0].src} />
                </Link>
              </div>
              <div className="col-md-6 col-8 d-flex align-items-center justify-content-center">
                <form className="input-group d-flex">
                  <Search />
                </form>
              </div>
              <div className="col-md-3 d-flex align-items-center justify-content-end Login-Register">
                {(userInfo?.isAdmin || userInfo?.isSuperAdmin) && (
                  <div className="go-to-dashboard">
                    <Link to="/dashboard" title="Kontrolni panel">
                      <i className="fal fa-toolbox"></i>
                    </Link>
                  </div>
                )}
                {userInfo ? (
                  <div className="btn-group">
                    <button
                      type="button"
                      className={`name-button dropdown-toggle site-bg border-1 border-focus-${theme}-500 border-transition-hover-${theme}-500 text-gray`}
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Cao, {userInfo.name}
                    </button>
                    <div className="dropdown-menu shadow bg-white header-popup">
                      <Link
                        className={`text-transition-hover-${theme}-500 text-transition-gray bg-hover-white dropdown-item`}
                        to="/profile"
                      >
                        <i className="fal fa-user"></i> Profil
                        <span
                          className={`bg-${theme}-500 bottom-line-active`}
                        ></span>
                      </Link>

                      <Link
                        className={`text-transition-hover-${theme}-500 text-transition-gray bg-hover-white dropdown-item`}
                        to="#"
                        onClick={logoutHandler}
                      >
                        <span>
                          <i className="fal fa-sign-out-alt"></i> Odjava
                        </span>

                        <span
                          className={`bg-${theme}-500 bottom-line-active`}
                        ></span>
                      </Link>
                    </div>
                  </div>
                ) : (
                  <>
                    <Link className={`text-${theme}-500`} to="/register">
                      Registracija
                    </Link>
                    <Link className={`text-${theme}-500`} to="/login">
                      Prijava
                    </Link>
                  </>
                )}
                <Favorite />

                {!disableShop && <CartPopover />}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`Announcement position-sticky top-0 d-lg-block d-md-block d-none ${
          isHeaderSticky ? `bg-${theme}-500 border-0` : "bg-white"
        }`}
      >
        <div className="container">
          <div className="row">
            <DesktopMenu isHeaderSticky={isHeaderSticky} />
          </div>
        </div>
      </div>
    </>
  );
}
