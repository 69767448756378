import React, { useState, useEffect, useRef, useMemo } from "react";
import { debounce, isEmpty } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import { listProductSearch } from "../../../Redux/client/Actions/ProductActions";
import Button from "./Button";
import { useNavigate } from "react-router-dom";

export default function Search({
  className,
  label,
  inputClass,
  listStyle,
  liRemoveTruncate,
}) {
  const uniqueId = uuidv4();
  const selectListRef = useRef(null);
  const [query, setQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [topBottom, setTopBottom] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [options, setOptions] = useState([]);

  const productsData = useSelector((state) => state.productSearch);
  const { loading, error, products } = productsData;

  const themeData = useSelector((state) => state.settings);
  const { theme } = themeData;

  const dispatch = useDispatch();
  const navigate = useNavigate(); // Using useNavigate instead of useHistory

  const memoizedSearch = useMemo(() => {
    return debounce((value) => {
      onChange(value);
    }, 500);
  }, []);

  const submitHandler = (e) => {
    e.preventDefault();
    onClear();
    if (query?.trim()) {
      navigate(`/shop/${undefined}`, { state: { keywordProp: query } });
    }
  };

  const onChange = (value) => {
    setIsOpen(true);
    dispatch(listProductSearch({ keyword: value }));
  };

  const handleOptionClick = (option) => {
    onClear();
    if (option !== "Clear") {
      navigate(`/products/${option._id}`);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "ArrowDown" || e.key === "ArrowUp") {
      e.preventDefault();
      const direction = e.key === "ArrowDown" ? 1 : -1;
      const newIndex = Math.max(0, Math.min(selectedIndex + direction, options.length - 1));
      setSelectedIndex(newIndex);
    } else if (e.key === "Enter") {
      if (selectedIndex !== -1) {
        handleOptionClick(options[selectedIndex]);
      } else {
        setIsOpen(!isOpen);
      }
    }
  };

  useEffect(() => {
    if (products) {
      setOptions(products);
    }
  }, [products]);

  const topBottomPosition = () => {
    const rect = selectListRef.current?.getBoundingClientRect();
    if (rect && rect.bottom > window.innerHeight) setTopBottom(true);
  };

  const onBlur = (e) => {
    if (
      !e.target.classList.contains("select-search-item") &&
      !e.target.classList.contains("search-input") &&
      e.target.id !== uniqueId
    ) {
      setIsOpen(false);
      return;
    }
  };

  const onFocus = () => {
    if (!isEmpty(options) && query) {
      setIsOpen(true);
    }
  };

  const onClear = () => {
    setQuery("");
    setOptions([]);
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen) topBottomPosition();
  }, [isOpen]);

  useEffect(() => {
    window.addEventListener("click", onBlur);
    return () => window.removeEventListener("click", onBlur);
  }, []);

  return (
    <div className={`search-wrapper d-flex align-items-center w-100 ${className}`}>
      <div className="position-relative d-flex w-100">
        <div className="position-relative flex-grow-1">
          <input
            id={uniqueId}
            type="text"
            className={`p-2 search search-input h-100 w-100 border-1 border-focus-${theme}-500 border-transition-hover-${theme}-500 ${inputClass}`}
            placeholder="Pretraga proizvoda"
            label={label}
            value={query}
            onChange={(e) => {
              setQuery(e.target.value);
              memoizedSearch(e.target.value);
            }}
            onKeyDown={handleKeyDown}
            onFocus={onFocus}
            onBlur={onBlur}
            aria-expanded={isOpen}
            aria-controls="search-list"
            aria-activedescendant={options[selectedIndex]?.id}
          />
          {loading && (
            <div
              style={{ top: "10px", right: "10px" }}
              className="spinner-border position-absolute spinner-border-sm text-gray"
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </div>
          )}
          {!loading && query && isOpen && (
            <div
              style={{ top: "9px", right: "10px" }}
              className="position-absolute pointer text-gray"
              onClick={onClear}
            >
              <i className="fal fa-times"></i>
            </div>
          )}
        </div>
        <Button className="w-auto px-4 text-white" onClick={submitHandler}>
          <i className="fas fa-search"></i>
        </Button>

        {isOpen && !error && !isEmpty(options) && (
          <ul
            style={{ top: "34px", zIndex: "99", maxHeight: "435px" }}
            ref={selectListRef}
            tabIndex={0}
            className={`position-absolute w-100 mt-1 py-0 px-0 site-bg overflow-auto shadow-lg ring-1 focus:outline-none sm:text-sm list-none ${
              topBottom ? "bottom-full" : ""
            } ${listStyle ? listStyle : ""}`}
            onKeyDown={handleKeyDown}
            id="search-list"
          >
            {options.map((option, index) => (
              <li
                style={{ borderBottom: "1px solid #c8c8c8" }}
                key={option._id}
                className={`relative select-search-item select-none pointer py-2 ${
                  index === selectedIndex ? "active-option" : ""
                }`}
                onClick={() =>
                  option === "Clear" && index === 0
                    ? onClear()
                    : handleOptionClick(option)
                }
              >
                <div className="d-flex justify-content-between align-items-center px-3">
                  <div>
                    <img
                      src={option.imageFiles[0].src}
                      alt="Slika"
                      height={70}
                    />
                  </div>
                  <div>
                    <p className={`${liRemoveTruncate ? "" : "text-truncate"}`}>
                      {option?.name}
                    </p>
                    <h5>{option.price} RSD</h5>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        )}
        {error && query && isOpen && (
          <div
            style={{ top: "34px" }}
            className="position-absolute w-100 p-3 site-bg shadow text-gray"
          >
            <span>{error}</span>
          </div>
        )}
      </div>
    </div>
  );
}
