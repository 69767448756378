import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import ComplaintModal from "./ComplaintModal";

const OrderDetailProducts = ({ order, loading }) => {
  const [showModal, setShowModal] = useState(false);
  const [editItem, setEditItem] = useState({});

  const themeData = useSelector((state) => state.theme);
  const { theme } = themeData;

  if (!loading) {
    // Calculate Price
    const addDecimals = (num) => {
      return (Math.round(num * 100) / 100).toFixed(2);
    };

    const updatedOrder = {
      ...order,
      itemsPrice: addDecimals(
        order.orderItems.reduce((acc, item) => acc + item.price * item.qty, 0)
      ),
    };

    console.log("updatedOrder:::", updatedOrder);
  }

  return (
    <>
      <table
        className={`table border table-lg ${
          theme === "dark" ? "bg-dark text-light" : ""
        }`}
      >
        <thead>
          <tr>
            <th style={{ width: "40%" }}>Proizvod</th>
            <th style={{ width: "20%" }}>Cena</th>
            <th style={{ width: "10%" }}>Kolicina</th>
            <th style={{ width: "10%" }}>Reklamacija</th>
            <th style={{ width: "20%" }} className="text-end">
              Ukupno
            </th>
          </tr>
        </thead>
        <tbody>
          {order.orderItems.map((item, index) => (
            <tr
              className={`${theme === "dark" ? "dark-theme-hover" : ""}`}
              key={index}
            >
              <td>
                <Link className="itemside" to="#">
                  <div className="left">
                    <img
                      src={item.images[0]?.src}
                      alt={item.name}
                      style={{ width: "40px", height: "40px" }}
                      className="img-xs"
                    />
                  </div>
                  <div
                    className={`info ${theme === "dark" ? "text-white" : ""}`}
                  >
                    {item.name}
                  </div>
                </Link>
              </td>
              <td>{item.price} RSD</td>
              <td>{item.qty}</td>
              <td>
                <button
                  className="btn btn-outline text-success"
                  onClick={() => {
                    setEditItem(item);
                    setShowModal(true);
                  }}
                >
                  <i className="fas fa-edit"></i>
                </button>
              </td>
              <td className="text-end">{item.qty * item.price} RSD</td>
            </tr>
          ))}

          <tr className={`${theme === "dark" ? "dark-theme-hover" : ""}`}>
            <td colSpan="5">
              <article className="float-end">
                <dl className="dlist">
                  <dt>UKUPNO:</dt> <dd>{order.itemsPrice} RSD</dd>
                </dl>
              </article>
            </td>
          </tr>
        </tbody>
      </table>
      <ComplaintModal
        show={showModal}
        setShow={setShowModal}
        item={editItem}
        orderId={order._id}
        onHide={() => setShowModal(false)}
      />
    </>
  );
};

export default OrderDetailProducts;
