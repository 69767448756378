import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getSettings } from "./Redux/dashboard/Actions/SettingsActions";

// Import styles
import "./App.scss";
import "./responsive.css";
import "./dashboard.scss";
import "./dashboard-responsive.css";
import "./dashboard-theme.scss";
import "react-toastify/dist/ReactToastify.css";

// Import client screens
import HomeScreen from "./screens/client/HomeScreen";
import ContactScreen from "./screens/client/ContactScreen";
import ShopScreen from "./screens/client/ShopScreen";
import BlogClientScreen from "./screens/client/BlogClientScreen";
import SingleBlog from "./screens/client/SingleBlogScreen";
import SellerShopScreen from "./screens/client/SellerShopScreen";
import SingleProduct from "./screens/client/SingleProductScreen";
import Login from "./screens/client/Login";
import Register from "./screens/client/Register";
import CartScreen from "./screens/client/CartScreen";
import FavoriteScreen from "./screens/client/FavoriteScreen";
import ShippingScreen from "./screens/client/ShippingScreen";
import ProfileScreen from "./screens/client/ProfileScreen";
import PaymentScreen from "./screens/client/PaymentScreen";
import PlaceOrderScreen from "./screens/client/PlaceOrderScreen";
import OrderScreen from "./screens/client/OrderScreen";
import ForgotPassword from "./screens/client/ForgotPassword";
import ResetPassword from "./screens/client/ResetPassword";
import NotFound from "./screens/client/NotFound";

// Import dashboard screens
import DashProfileScreen from "./screens/dashboard/ProfileScreen";
import DashHomeScreen from "./screens/dashboard/HomeScreen";
import ProductScreen from "./screens/dashboard/ProductScreen";
import CategoryScreen from "./screens/dashboard/CategoryScreen";
import BrandScreen from "./screens/dashboard/BrandScreen";
import MaterialScreen from "./screens/dashboard/MaterialScreen";
import BlogScreen from "./screens/dashboard/BlogScreen";
import CategoryRequestScreen from "./screens/dashboard/CategoryRequestScreen";
import CategoryRequestsScreen from "./screens/dashboard/CategoryRequestsScreen";
import CategoryEditScreen from "./screens/dashboard/CategoryEditScreen";
import SellersScreen from "./screens/dashboard/SellersScreen";
import SellerEditScreen from "./screens/dashboard/SellerEditScreen";
import DashOrderScreen from "./screens/dashboard/OrderScreen";
import CommissionScreen from "./screens/dashboard/CommissionScreen";
import OrderDetailScreen from "./screens/dashboard/OrderDetailScreen";
import SettingsScreen from "./screens/dashboard/SettingsScreen";
import AddProduct from "./screens/dashboard/AddProduct";
import UsersScreen from "./screens/dashboard/UsersScreen";
import SingleUserScreen from "./screens/dashboard/SingleUserScreen";
import ProductEditScreen from "./screens/dashboard/ProductEditScreen";
import Chat from "./screens/dashboard/ChatScreen";
import ProcurementScreen from "./screens/dashboard/ProcurementScreen";
import TodoScreen from "./screens/dashboard/TodoScreen";

// Import private routers
import {
  LogedinRouter,
  SuperAdminRouter,
  BothAdminRouter,
  AdminRouter,
} from "./PrivateRouter";
import ShopBrandScreen from "./screens/client/ShopBrandScreen";

const App = () => {
  const dispatch = useDispatch();
  const settingsData = useSelector((state) => state.settings);
  const { disableShop } = settingsData;

  useEffect(() => {
    dispatch(getSettings());
  }, [dispatch]);

  return (
    <Router>
      <Routes>
        {/* Client Routes */}
        <Route path="/" element={<HomeScreen authed={true} />} />
        <Route path="/search/:keyword" element={<HomeScreen />} />
        <Route path="/page/:pagenumber" element={<HomeScreen />} />
        <Route path="/contact" element={<ContactScreen />} />
        <Route path="/client-blogs" element={<BlogClientScreen />} />
        <Route path="/client-blogs/:id" element={<SingleBlog />} />
        <Route path="/products/:id" element={<SingleProduct />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/cart/:id?" element={<CartScreen />} />
        <Route path="/favorite" element={<FavoriteScreen />} />
        <Route path="/shop/:slug" element={<ShopScreen />} />
        <Route path="/shop-brand/:brand" element={<ShopBrandScreen />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route
          path="/reset-password/:userId/:token"
          element={<ResetPassword />}
        />
        <Route path="/shop-seller/:sellerId" element={<SellerShopScreen />} />

        {/* Authenticated Routes */}
        <Route element={<LogedinRouter />}>
          <Route path="/profile" element={<ProfileScreen />} />
          <Route path="/payment" element={<PaymentScreen />} />
          <Route path="/order/:id" element={<OrderScreen />} />
        </Route>

        {/* Dashboard Routes */}
        <Route element={<BothAdminRouter />}>
          <Route path="/dashboard" element={<DashHomeScreen />} />
          <Route path="/dashboard-profile" element={<DashProfileScreen />} />
          <Route path="/dashboard-products" element={<ProductScreen />} />
          <Route path="/addproduct" element={<AddProduct />} />
          <Route path="/users" element={<UsersScreen />} />
          <Route path="/users/:id" element={<SingleUserScreen />} />
          <Route path="/product/:id/edit" element={<ProductEditScreen />} />
          <Route path="/dashboard-order/:id" element={<OrderDetailScreen />} />
          <Route path="/orders" element={<DashOrderScreen />} />
          <Route path="/chat" element={<Chat />} />
        </Route>

        <Route element={<SuperAdminRouter />}>
          <Route path="/category" element={<CategoryScreen />} />
          <Route
            path="/category-edit-info/:id"
            element={<CategoryEditScreen />}
          />
          <Route path="/brands" element={<BrandScreen />} />
          <Route path="/materials" element={<MaterialScreen />} />
          <Route path="/blogs" element={<BlogScreen />} />
          <Route path="/sellers" element={<SellersScreen />} />
          <Route path="/seller-edit-info/:id" element={<SellerEditScreen />} />
          <Route path="/procurement" element={<ProcurementScreen />} />
          <Route path="/commissions" element={<CommissionScreen />} />
          <Route
            path="/category-requests"
            element={<CategoryRequestsScreen />}
          />
          <Route path="/todos" element={<TodoScreen />} />
          <Route path="/settings" element={<SettingsScreen />} />
        </Route>

        <Route element={<AdminRouter />}>
          <Route path="/category-request" element={<CategoryRequestScreen />} />
        </Route>

        {/* Shop Disabled */}
        {!disableShop && (
          <>
            <Route path="/shipping" element={<ShippingScreen />} />
            <Route path="/placeorder" element={<PlaceOrderScreen />} />
          </>
        )}

        {/* 404 Not Found */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default App;
